<template>
  <div class="page-class page-setting-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <schedule-list-box
      :columns="columns"
      :rows="scheduleList"
      :baseUrl="baseUrl"
      :loading="loading"
      :pagination="pagination"
      emptyText="No Schedules found."
      downloadText="Download Schedules"
      :showAdd="false"
      :downloadReportText="'Download Report'"
      :showReportDownload="true"
      :showPagination="true"
      :showFilter="true"
      :showSearch="true"
      :filterOptions="filterOptions"
      :steps="steps"
      :callbacks="callbacks"
      :showToolbar="true"
      :showDownload="true"
      :searchItems="searchItems"
      @download="onExportCSV"
      @downloadreport="onDownloadReport"
    >
    </schedule-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import ScheduleListBox from '@/components/ScheduleListBox.vue';
import _ from 'lodash';
import router from '@/router';

export default {
  name: 'ScheduleList',
  components: {
    ScheduleListBox
  },
  metaInfo() {
    return {
      title: 'Schedules',
      meta: [
        {
          name: 'description',
          content: 'View Schedules'
        }
      ]
    };
  },
  mounted() {
    this.downloadNotesReport({});
    // if (window.localStorage.getItem('hasCompletedSchedule') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listSchedule({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listSchedule({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listSchedule({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Schedules',
      notesData: [],
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedSchedule', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
          content: `Select Schedule Filter to further refine search Results.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Download Schedule List.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Schedule Type',
          textKey: 'scheduleType',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Store To Visit',
          textKey: 'stores',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Schedule Date',
          textKey: 'scheduleDate',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Created By',
          textKey: 'createdByName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          textKey: 'createdAt',
          width: '15%'
        }
        // {
        //   type: 'functions',
        //   headerText: 'Functions',
        //   class: { 'text-center': true },
        //   width: '10%',
        //   functions: {
        //     open: true,
        //     edit: false
        //   }
        // }
      ],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Schedule_Type-WEEK_OFF', text: 'Schedule Type-WEEK OFF' },
        { value: 'Schedule_Type-LEAVE', text: 'Schedule Type-LEAVE' },
        { value: 'Schedule_Type-ROHO', text: 'Schedule Type-RO/HO' },
        { value: 'Schedule_Type-STORE_VISIT', text: 'Schedule Type-Store  Visit' }
      ],
      searchItems: ['ASM Name']
    };
  },
  computed: {
    ...mapState('schedule', ['loading', 'baseUrl', 'scheduleList', 'pagination', 'downloadSchedulesList', 'report']),
    ...mapState('notes', ['notesReport'])
  },
  watch: {
    notesReport(newValue) {
      if (newValue) {
        const live = newValue.liveData;
        const Ld = newValue.lastDay;
        const MTD = newValue.monthTillDate;

        const liveData = live.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.department,
          currentDay: i.liveData
        }));

        const lastDayData = Ld.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.department,
          lastDay: i.LD
        }));

        const monthTillDateData = MTD.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.department,
          monthTillDate: i.MTD
        }));

        const arr = [...liveData, ...lastDayData, ...monthTillDateData];

        const uniqueEmployees = [];
        arr.forEach(i => {
          if (!uniqueEmployees.includes(i.employeeName)) {
            uniqueEmployees.push(i.employeeName);
          }
        });

        const modifiedData = uniqueEmployees.map(i => ({
          employeeName: i,
          region: arr.find(j => j.employeeName === i).region,
          department: arr.find(j => j.employeeName === i).role,
          currentDayNotes: [],
          lastDayNotes: [],
          monthTillDateNotes: []
        }));
        arr.forEach(i => {
          const index = modifiedData.findIndex(j => j.employeeName === i.employeeName);
          if (i.currentDay) {
            modifiedData[index].currentDayNotes.push(i.currentDay);
          }
          if (i.lastDay) {
            modifiedData[index].lastDayNotes.push(i.lastDay);
          }
          if (i.monthTillDate) {
            modifiedData[index].monthTillDateNotes.push(i.monthTillDate);
          }
        });

        this.notesData.push(modifiedData);
      }
    },
    report(newvalue) {
      if (newvalue) {
        const live = newvalue.liveData;
        const Ld = newvalue.lastDayData;
        const MTD = newvalue.monthTillDateData;

        const liveData = live.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.role,
          currentDay: i.liveData
        }));

        const lastDayData = Ld.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.role,
          lastDay: i.lastDayData
        }));

        const monthTillDateData = MTD.map(i => ({
          region: i.region,
          employeeName: i.employeeName,
          role: i.role,
          monthTillDate: i.monthTillDateData
        }));

        const mergeAll = [...liveData, ...lastDayData, ...monthTillDateData];
        const uniqueEmployees = [];
        mergeAll.forEach(i => {
          if (!uniqueEmployees.includes(i.employeeName)) {
            uniqueEmployees.push(i.employeeName);
          }
        });
        const modifiedData = uniqueEmployees.map(i => ({
          employeeName: i,
          region: mergeAll.find(j => j.employeeName === i).region,
          role: mergeAll.find(j => j.employeeName === i).role,
          liveData: [],
          lastDayData: [],
          monthTillDateData: []
        }));
        mergeAll.forEach(i => {
          const index = modifiedData.findIndex(j => j.employeeName === i.employeeName);
          if (i.currentDay) {
            modifiedData[index].liveData.push(i.currentDay);
          }
          if (i.lastDay) {
            modifiedData[index].lastDayData.push(i.lastDay);
          }
          if (i.monthTillDate) {
            modifiedData[index].monthTillDateData.push(i.monthTillDate);
          }
        });

        const uniqueEmployees2 = [];
        const newData = this.notesData.flat();
        newData.forEach(i => {
          if (!uniqueEmployees2.includes(i.employeeName)) {
            uniqueEmployees2.push(i.employeeName);
          }
        });

        const modifiedData2 = uniqueEmployees2.map(i => ({
          employeeName: i,
          region: newData.find(j => j.employeeName === i).region,
          role: newData.find(j => j.employeeName === i).department,
          currentDayNotes: [],
          lastDayNotes: [],
          monthTillDateNotes: []
        }));

        newData.forEach(i => {
          const index = modifiedData2.findIndex(j => j.employeeName === i.employeeName);
          if (i.currentDayNotes) {
            modifiedData2[index].currentDayNotes.push(i.currentDayNotes);
          }
          if (i.lastDayNotes) {
            modifiedData2[index].lastDayNotes.push(i.lastDayNotes);
          }
          if (i.monthTillDateNotes) {
            modifiedData2[index].monthTillDateNotes.push(i.monthTillDateNotes);
          }
        });

        const newNotesData = modifiedData2.map(i => ({
          ...i,
          currentDayNotes: i.currentDayNotes.flat(),
          lastDayNotes: i.lastDayNotes.flat(),
          monthTillDateNotes: i.monthTillDateNotes.flat()
        }));

        const scheduleEmployee = modifiedData.map(i => i.employeeName);
        const notesEmployee = newNotesData.map(i => i.employeeName);
        for (let i = 0; i < notesEmployee.length; i++) {
          if (scheduleEmployee.includes(notesEmployee[i])) {
            const index = modifiedData.findIndex(j => j.employeeName === notesEmployee[i]);
            modifiedData[index].currentDayNotes = newNotesData.find(
              j => j.employeeName === notesEmployee[i]
            ).currentDayNotes;
            modifiedData[index].lastDayNotes = newNotesData.find(j => j.employeeName === notesEmployee[i]).lastDayNotes;
            modifiedData[index].monthTillDateNotes = newNotesData.find(
              j => j.employeeName === notesEmployee[i]
            ).monthTillDateNotes;
          }
        }

        let csv = '';
        csv += `,,Report Date  ${moment().format('DD-MM-YYYY')} \n`;
        csv += `,,,\n`;
        csv += `,,,,Visit Scheduled,,, Visit Notes,\n`;
        csv += 'Region,Name Of Employee,Role,Live,Last Day,MTD,Live,Last Day,MTD\n';
        modifiedData.forEach(i => {
          csv += `"${i.region}","${i.employeeName}","${i.role}",${
            i.liveData === undefined ? 0 : i.liveData && i.liveData.length === 0 ? 0 : i.liveData
          },${i.lastDayData === undefined ? 0 : i.lastDayData && i.lastDayData.length === 0 ? 0 : i.lastDayData},${
            i.monthTillDateData === undefined
              ? 0
              : i.monthTillDateData && i.monthTillDateData.length === 0
              ? 0
              : i.monthTillDateData
          },${
            i.currentDayNotes === undefined
              ? 0
              : i.currentDayNotes && i.currentDayNotes.length === 0
              ? 0
              : i.currentDayNotes
          },${i.lastDayNotes === undefined ? 0 : i.lastDayNotes && i.lastDayNotes.length === 0 ? 0 : i.lastDayNotes},${
            i.monthTillDateNotes === undefined
              ? 0
              : i.monthTillDateNotes && i.monthTillDateNotes.length === 0
              ? 0
              : i.monthTillDateNotes
          }\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Schedule Report.csv';
        anchor.click();
      }
    },

    downloadSchedulesList(newValue) {
      if (newValue.length) {
        let csv = 'Row Number,Schedulte Type,Store To Visit,Schedule Date,Created By,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if ([1, 2, 3, 4, 5, 6].includes(index)) {
              csv += `"${val}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Schedules.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('schedule', ['listSchedule', 'download', 'downloadReport']),
    ...mapActions('notes', ['downloadNotesReport']),

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 9999,
            page: 1,
            startdate: moment().subtract(1, 'months').format('MMM YYYY')
          }
        });
      }
    },
    onDownloadReport({}) {
      this.downloadReport({ query: { ...this.$route.query } });
    }
  }
};
</script>
